import { FunctionComponent } from "react";

interface ErrorPageProps {

}

const ErrorPage: FunctionComponent<ErrorPageProps> = () => {


    return (<div>404 NotFound!</div>)
}

export default ErrorPage;