import React from "react";
import { ISkillItem } from "./../skillModel";
import SkillItem from "../skillItem/skillItem";
interface SkillListProps {
    list: ISkillItem[];
}
 
const SkillList: React.FC<SkillListProps> = ({list}) => {
    return (  
            <ul className="skills-info skills-second-style">
                {            
                    list.map((item, i) =>
                        (<li key={i}>
                            <SkillItem  item={item}/>
                        </li>)
                    )
                }
            </ul>
        );
} 

export default SkillList;  